<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="lg"
    :on-modal-hidden="resetModal"
  >
    <template #title>
      Invia fattura
    </template>
    <template #body>
      <div v-if="previewMode">
        <modal-edit-mail-form
          :modal-id="id"
          :enable-preview-mode="enablePreviewMode"
        />
      </div>
      <div v-else>
        <modal-send-mail-form
          :modal-id="id"
          :entity-id="entityId"
          :entity-type="entityType"
          :context="context"
          :environment="environment"
          :language="language"
          :mails="mails"
          :mailCode="mailCode"
          :enable-preview-mode="enablePreviewMode"
        />
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import ModalEditMailForm from '@/components/userInvoice/ModalEditMailForm.vue';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const ModalSendMailForm = () => import('./ModalSendMailForm.vue');

export default {
  name: 'ModalSendMail',
  props: {
    id: String,
    entityType: String,
    entityId: String,
    context: String,
    environment: String,
    language: String,
    onSuccess: Function,
    mails: Array,
    mailCode: String,
  },
  components: {
    ModalEditMailForm,
    ModalWrapper,
    ModalSendMailForm,
  },
  mounted() {
    this.previewMode = false;
  },
  data() {
    return {
      submitted: false,
      showCompany: false,
      hasErrors: false,
      error: null,
      previewMode: false,
    };
  },
  computed: {},
  methods: {
    enablePreviewMode(mode) {
      console.log('Invoke enablePreviewMode', mode);
      this.previewMode = mode;
    },
    resetModal() {
      this.previewMode = false;
    },
  },
};
</script>

<style scoped>

</style>
