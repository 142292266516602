<template>
  <div>
    <div
      v-if="!hasPreview"
      class="my-4"
    >
      <loader-info />
    </div>
    <div v-else>
      <div>
        <h5>
          {{recipientsLabel}}
        </h5>
        <field-multi-mail
          :label="recipientToLabel"
          v-model="recipientToValue"
          :placeholder="recipientToPlaceholder"
          :defaultEmails="defaultEmailsTo"
        />
        <field-multi-mail
          :label="recipientCcLabel"
          v-model="recipientCcValue"
          :placeholder="recipientCcPlaceholder"
          :defaultEmails="defaultEmailsCc"
        />
        <field-multi-mail
          :label="recipientBccLabel"
          v-model="recipientBccValue"
          :placeholder="recipientBccPlaceholder"
          :defaultEmails="defaultEmailsBcc"
        />
      </div>
      <div class="mt-4">
        <h5>
          {{subjectLabel}}
        </h5>
        <div>
          <field-text
            fieldId="object"
            :placeholder="subjectLabel"
            v-model="subjectMail"
            :state="null"
            class="flex-grow-1"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h5>
        {{bodyLabel}}
      </h5>
      <div class="tinymce-fake-loader">
        <div>
          <loader-info />
        </div>
        <div>
          <editor
            :api-key="apiKey"
            @onChange="updateBody($event)"
            :init="config"
            v-model="htmlBody"
          />
        </div>
      </div>
    </div>
    <div class="mt-3 pt-3 border-top d-flex">
      <div>
        <b-btn size="sm" variant="warning" @click="closeModal">Chiudi</b-btn>
        <b-btn
          size="sm"
          variant="warning"
          @click="enablePreviewMode(false)"
          class="ms-3"
        >
          Indietro
        </b-btn>
      </div>
      <b-btn
      :disabled="submitted || !validateData"
      size="sm"
      variant="success"
      @click="sendMail"
      class="ms-auto"
    >
      Invia Email
    </b-btn>
    </div>
  </div>
</template>

<script>
import { tinyMceAPIKey } from '@/utils/utilities';
import { isPresent, isArrayNotEmpty } from '@/utils/validators';
import { editorConfig } from '@/utils/formatterHelper';
import FieldText from '@/components/fields/FieldText.vue';

const FieldMultiMail = () => import('@/components/fields/FieldMultiMail.vue');
const Editor = () => import('@tinymce/tinymce-vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'ModalEditMailForm',
  components: {
    FieldText,
    FieldMultiMail,
    Editor,
    LoaderInfo,
  },
  props: {
    modalId: String,
    enablePreviewMode: Function,
  },
  data() {
    return {
      submitted: false,
      apiKey: tinyMceAPIKey,
      subjectMail: null,
      htmlBody: null,
      channelConfig: null,
      recipientToValue: [],
      recipientCcValue: [],
      recipientBccValue: [],
      config: { ...editorConfig, height: 300 },
    };
  },
  watch: {
    defaultEmailsTo(val) {
      if (isArrayNotEmpty(val)) {
        this.prepareEmails();
      }
    },
  },
  computed: {
    generatedMail() {
      this.setBody();
      console.log('genMail', this.$store.getters['sendmail/getPreview']);
      return this.$store.getters['sendmail/getPreview'];
    },
    hasPreview() {
      return this.$store.getters['sendmail/hasPreview'];
    },
    recipientsLabel() {
      return 'Destinatari';
    },
    recipientToLabel() {
      return 'To:';
    },
    recipientToPlaceholder() {
      return 'Inserire i destinatari (To)';
    },
    defaultEmailsTo() {
      if (this.generatedMail?.toList?.length > 0) {
        const emails = this.generatedMail.toList.map((email) => email.destinationEmail);
        // console.log('to emails', emails);
        // return [...this.channelConfig.toAddress];
        return emails;
      }
      return [];
    },
    recipientCcLabel() {
      return 'Cc:';
    },
    recipientCcPlaceholder() {
      return 'Inserire i destinatari (Cc)';
    },
    defaultEmailsCc() {
      if (this.generatedMail?.ccList?.length > 0) {
        const emails = this.generatedMail.ccList.map((email) => email.destinationEmail);
        return emails;
      }
      return [];
    },
    recipientBccLabel() {
      return 'Bcc:';
    },
    recipientBccPlaceholder() {
      return 'Inserire i destinatari (Bcc)';
    },
    defaultEmailsBcc() {
      if (this.generatedMail?.bccList?.length > 0) {
        const emails = this.generatedMail.bccList.map((email) => email.destinationEmail);
        return emails;
      }
      return [];
    },
    subjectLabel() {
      return 'Oggetto della mail';
    },
    bodyLabel() {
      return 'Testo della mail';
    },
    validateData() {
      return this.recipientCcValue.length !== 0 || this.recipientBccValue.length !== 0 || this.recipientToValue.length !== 0;
    },
  },
  mounted() {},
  methods: {
    sendMail() {
      console.log('send mail');
      this.$store.dispatch('sendmail/sendUpdateMail', {
        htmlBody: this.htmlBody,
        subject: this.subjectMail,
        toList: this.recipientToValue,
        ccList: this.recipientCcValue,
        bccList: this.recipientBccValue,
      })
        .then((result) => {
          console.log('send mail result', result);
          this.closeModal();
        })
        .catch((error) => {
          console.log('send mail error', error);
        });
    },
    setBody() {
      if (!isPresent(this.htmlBody)) {
        const preview = this.$store.getters['sendmail/getPreview'];
        this.htmlBody = preview?.message?.body;
        this.subjectMail = preview?.message?.subject;
      }
    },
    updateBody(e) {
      // console.log('closeComment value', e, e.target.getContent());
      this.htmlBody = e.target.getContent();
    },
    closeModal() {
      // console.log('close modal / reset');
      if (this.tinymce) this.tinymce.remove('#editor');
      this.$bvModal.hide(this.modalId);
    },
    prepareEmails() {
      this.recipientToValue = this.defaultEmailsTo;
      this.recipientCcValue = this.defaultEmailsCc;
      this.recipientBccValue = this.defaultEmailsBcc;
    },
  },
};
</script>

<style scoped>

</style>
